import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import Select from "react-select";
import close from "../../../Common/Assets/Images/Close.png";
import empty from "../../../Common/Assets/Images/emptystate.png";
import Notify from "../../../Helpers/Toast";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  points: number;
}

const RedeemModal: React.FC<ModalProps> = ({ isOpen, onClose, points }) => {
  const navigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<any>();
  const [paymentId, setPaymentId] = useState("mtn");

  const handlePaymentMethods = (selectedPayment: any) => {
    setSelectedPayment(selectedPayment);
    setPaymentId(selectedPayment.value);
  };

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#E5E4E2",

      textAlign: "left",
    },
  });

  const paymentStyles = {
    control: (styles: any) => ({
      ...styles,
      border: "1px solid #E0E0E0",

      height: "53px",
      borderRadius: "10px",
    }),
  };

  const fetchPaymentMethods = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/payments/user?type=PAYMENT`
    );
    return response.data;
  };

  const { data: paymentsData } = useQuery({
    queryKey: ["paymentMethods"],
    queryFn: fetchPaymentMethods,
  });

  const redeemPoints = async () => {
    const response = await axiosInstance.post(
      `${Constants.API_BASE_URL}/users/redeem/points`,
      {
        paymentMethodId: paymentId,
      }
    );
    return response.data;
  };

  const { mutate: redeem } = useMutation({
    mutationFn: redeemPoints,
    onSuccess: (data) => {
      // console.log("data", data);
      onClose();
      Notify("top-center", "Points redeemed ! 🎉");
    },
    onError: (error) => {
      console.log("error", error);
      Notify("top-center", "Sorry, something went wrong, try again", "error");
    },
  });

  useEffect(() => {
    if (paymentsData) {
      setPaymentMethods(
        paymentsData.map((payment: any) => ({
          value: payment?.id,
          label: payment?.accountNumber,
          image: payment?.payment?.logoUrl,
        }))
      );
    }
  }, [paymentsData]);

  // console.log("paymentsData", paymentMethods);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 overflow-y-auto z-50">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center bg-white p-8 rounded-lg shadow-md w-[480px] max-w-[480px]">
          <h2 className="text-2xl font-bold mb-4">Redeem Your Points! 🎉 </h2>

          <div className="mb-4">
            {/* Modal content goes here */}
            <p>Number of points to redeem </p>
            <span>
              {points} pts ≈ GHS {points / 100 || 0}{" "}
            </span>
          </div>

          <button onClick={onClose} className="absolute top-0 right-0 p-4">
            <img src={close} alt="Close" className="h-3 w-3" />
          </button>

          <div className=" mt-2  w-full">
            {paymentsData ? (
              <Select
                required
                formatOptionLabel={(paymentMethods) => (
                  <div className="flex items-center">
                    {paymentMethods?.image && (
                      <img
                        src={paymentMethods?.image}
                        className="w-8 h-8 rounded-full"
                        alt=""
                      />
                    )}
                    <span className="text-[14px] font-medium ml-2">
                      {paymentMethods?.label}
                    </span>
                  </div>
                )}
                defaultValue={selectedPayment}
                onChange={handlePaymentMethods}
                options={paymentMethods}
                placeholder={
                  <div className="flex items-start">
                    <span className="text-[14px] font-medium ml-2 mt-1">
                      Select Your Payment Mehtod
                    </span>{" "}
                  </div>
                }
                styles={paymentStyles}
                theme={customeTheme}
                isSearchable={false}
                blurInputOnSelect={false}
              />
            ) : (
              <div>
                <div className="flex justify-center mt-6 p-12">
                  <img src={empty} alt="" className="w-24 h-24" />
                </div>
                <div className="font-bold  text-[20px]">
                  Looks like you don't have any payment methods
                </div>
                <div className="text-[14px] mt-2">
                  {" "}
                  Set up your payments in the settings{" "}
                </div>
              </div>
            )}
          </div>
          {paymentsData ? (
            <button
              className={` ${
                !selectedPayment
                  ? "bg-gray-200 text-gray-300"
                  : "bg-blue text-white"
              }
           
                           flex rounded-[10px] px-2 py-4 mt-6 items-center justify-center w-full cursor-pointer`}
              onClick={() => redeem()}
              disabled={!selectedPayment}
            >
              <p className="text-[15px]">Redeem </p>
            </button>
          ) : (
            <button
              className=
             
                 
                 "bg-blue text-white flex rounded-[10px] px-2 py-4 mt-6 items-center justify-center w-full cursor-pointer"
              onClick={() => navigate("/settings")}
              
            >
              <p className="text-[15px]"> Go to Settings </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
